import { NgModule } from '@angular/core';
import {
  RouterModule, Routes, PreloadingStrategy,
  PreloadAllModules
} from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.module')
      .then(m => m.DashboardModule)
  },
  {
    path: 'user-management',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/user-management/user-management.module')
      .then(m => m.UsermanagementModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/profile/profile.module')
      .then(m => m.ProfileModule)
  },
  {
    path: 'business-listing',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/business-listing/business-listing.module')
      .then(m => m.BusinessListingModule)
  },
  {
    path: 'admin-users',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/admin-users/admin-users.module')
      .then(m => m.AdminUsersModule)
  },
  {
    path: 'reviews',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/reviews/reviews.module')
      .then(m => m.ReviewsModule)
  },
  {
    path: 'cms',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/cms/cms.module')
      .then(m => m.CmsModule)
  },
  {
    path: 'blogs-management',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/blogs/blogs.module')
      .then(m => m.BlogsModule)
  },
  {
    path: 'payments',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/payments/payments.module')
      .then(m => m.PaymentsModule)
  },
  {
    path: 'deals',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/deals/deals.module')
      .then(m => m.DealsModule)
  },
  {
    path: 'orders',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/orders/orders.module')
      .then(m => m.OrdersModule)
  },


  { path: '**', component: LoginComponent },
];




@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
