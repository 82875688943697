<div class="account-pages"></div>
<div *ngIf="spinner.isLoading | async" class="loader-container">
  <mat-spinner class="load-spinner"></mat-spinner>
</div>
<div class="container">

  <div class="loginsignup-form">
    <div class="login-logo"><img alt="atlas" src="assets/images/logo.png">
    </div>
    <h3 class="text-center"> Forgot password </h3>
    <div class="clearfix">

      <form class="example-form" [formGroup]="forgotForm">
        <label class="label-email" for="email"><i class="fas fa-envelope email-box"></i> Email Address</label>
        <input class="input-field form-control form-control-sm" id="email" type="email" formControlName="email">
        <span class="span-val" *ngIf="email?.invalid && email?.touched">Email Address is required
        </span>

        <div class="form-group">

          <input [disabled]="forgotForm.invalid" (click)="onSubmit()" class="btn btn-primary btn-block float-right"
            type="button" value="Send">
          <div class="clearfix"></div>
          <!-- <span *ngIf="showSuccess" class="error">Hi there! we have sent a password reset link on your registered email.
            Kindly use the link
            to reset you password and login again.</span> -->
        </div>
        <div class="back-login">
          <a class="mt4 float-left" routerLink="/">Back to login</a>
        </div>

      </form>
    </div>
  </div>