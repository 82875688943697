export class UrlConstants{
    static readonly forgotPassword:string="/auth/forgot-password";
    static readonly resetPassword:string="/auth/reset-password";
  

/**
* User Module  Urls
*/     
    /**
     * customers Urls
     */ 
    static readonly customersUrl="/auth/user";
    static readonly updateCustomerStatusUrl="/auth/user/status";

  /**
     * upload photo Urls
     */
    static readonly uploadPhotoUrl:string="/upload";

/**
* Cms Module  Urls
*/ 
    /**
     * business Category Urls
     */
    static readonly categoryUrl="/auth/business-category";
    static readonly updateCategoryStatusUrl="/auth/business-category/status";
    static readonly categoryCheckOrderUrl="/auth/business-category/check-order";

    /**
     * business Group Urls
     */
    static readonly groupUrl="/auth/business-category/group";
    static readonly updateGroupStatusUrl="/auth/business-category/group/status";
    static readonly groupCheckOrderUrl="/auth/business-category/group/check-order";

     /**
     * business Types Urls
     */
     static readonly typeUrl="/auth/business-category/type";
     static readonly updateTypeStatusUrl="/auth/business-category/type/status";
     static readonly typeCheckOrderUrl="/auth/business-category/type/check-order";
 

    /**
     * business Tags Ans Keywords Urls
     */
    static readonly tagsAndKeywordsUrl="/auth/tags-keywords";
    static readonly updateAmenities="/auth/amenities";
    static readonly amenities="/auth/amenities";
    static readonly updatetagsAndKeywordsStatusUrl="/auth/tags-keywords/status";
    static readonly updateAmenitiesStatus="/auth/amenities/status";
    static readonly tagsAndKeywordsCheckOrderUrl="/auth/tags-keywords/check-order";
    
    /**
     * suggested Location
     */
    static readonly suggestedLocationUrl="/auth/notify";
    
     /**
     * business Banners Urls
     */
     static readonly bannerUrl="/cms/banner/list";
     static readonly editBannerUrl="/cms/banner";
     static readonly updateBannerStatusUrl="/cms/banner/status";

    /**
     * Link Category Urls
     */
      static readonly linkUrl="/cms/links";

    /**
     *Locations Urls
     */
    static readonly LocationsUrl="/auth/address";
    static readonly updateLocationStatusUrl="/auth/address/status";
    

/**
* Profile Module  Urls
*/ 

     /**
     * profile Urls
     */
     static readonly profileGetUrl:string="/auth/user/get-profile";
     static readonly profileEditUrl:string="/auth/user/edit-profile";


    /**
     * change password  Urls
     */
    static readonly changePasswordUrl:string="/auth/user/change-password";


/**
* Admin user Module  Urls
*/    

    /**
    * admin users  Urls
     */ 
    static readonly adminUserUrl:string="/auth/sub-admin";
    static readonly updateStatusAdminUserUrl:string="/auth/sub-admin/status";

/**
* BusinessListing Module Urls
*/     
     /**
    * active listing Urls
     */ 
     static readonly activeListingUrl:string="/auth/business-list";
     static readonly updateStatusactiveListingUrl:string="/auth/business-list/status";

    /**
    * listing Requesting Urls
     */ 
     static readonly listingRequestingUrl:string="/auth/business-list/pending";
     static readonly listingRequestStatusUrl:string="/auth/business-list/requests";
     /**
    * alluser Urls
     */ 
     static readonly allUsersUrl:string="/auth/user/all";
      /**
    * allCategories Urls
     */ 
     static readonly allCategoriesUrl:string="/auth/business-category/all";

    /**
    * allTags Urls
     */ 
     static readonly allTagsUrl:string="/auth/tags-keywords/all";

     /**
    * all amenities Urls
     */ 
     static readonly allAmenitiesUrl:string="/auth/amenities/all";


/**
 * dashboard url
 */     
    /**
     * Fetch dashboard count
     */
    static readonly dashBoardCountUrl:string="/auth/dashboard/count";
    static readonly locationRankingUrl:string="/auth/dashboard/location-ranking";
    static readonly viewRankingUrl:string ="/auth/dashboard/business-ranking";
    static readonly userBusinessGraphUrl:string ="/auth/dashboard/user-count-type";
    static readonly userPaymentCountUrl:string ="/subscription/plan/user-payment-count";
    static readonly subscriptionUserUrl:string ="/subscription/user";

}