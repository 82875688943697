import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  url!: string;
  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.url = environment.apiRoute + '/auth';
  }

  public login(data: { email: string, password: string }) {
    return this.http.post(
      `${this.url}/login`, data
    )
  }

  public forgotPassword(email: string) {
    return this.http.post(
      `${this.url}/forgot-password`, { email }
    )
  }

  public resetPassword(token: string, password: string) {
    return this.http.put(
      `${this.url}/set-new-password`, { token, password }
    )
  }

  public setToken(data: any): void {
    //localStorage.setItem('roleId', data.roles[0].roleId);
    localStorage.setItem('@$T0ken', data);
  }

  public getToken(): string | null {
    return localStorage.getItem('@$T0ken');
  }

  public isLoggedIn() {
    return this.getToken() !== null;
  }

  public logout() {
    localStorage.removeItem('@$T0ken');
    localStorage.removeItem('roleId');
    this.router.navigate(['login']).then(() => {
      window.location.reload();
    });
  }

  getAllKeywords() {
    return this.http.get(`${environment.apiRoute}/auth/tags-keywords/all`);
  }
  getAllCategories() {
    return this.http.get(`${environment.apiRoute}/auth/business-category/all`);
  }
  getAllGroups() {
    return this.http.get(`${environment.apiRoute}/auth/business-category/group/all`);
  }
}
